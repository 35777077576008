import api from '@/utils/request'

// 速查表
// /korean/syllable/getShorthandTable
export function getShorthandTable(params) {
  return api({
    method: 'get',
    url: '/korean/syllable/getShorthandTable',
    params,
  })
}

// 音节列表信息
// korean/syllable/getSyllableList
export function getSyllableList(params) {
  return api({
    method: 'get',
    url: '/korean/syllable/getSyllableList',
    params,
  })
}

// 音节详细信息
// korean/syllable/getSyllableList
export function getSyllableDetail(params) {
  return api({
    method: 'get',
    url: '/korean/syllable/getSyllableDetail',
    params,
  })
}
