<template>
  <div class="container learn">
    <div class="learn-header co_333">
      <div class="d-flex justify-content-center">
        <div
          class="learn-header-text d-flex align-items-end"
          v-for="(item, idx) in list"
          :key="idx"
        >
          <span :class="activeIndex == idx ? 'active' : ''">{{
            item.syllables
          }}</span>
        </div>
      </div>
      <div class="swiper_all">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide b-r12 bg-fff"
              v-for="(item, idx) in list"
              :key="idx"
            >
              <div class="swiper-info">
                <div class="img text-right">
                  <img
                    src="@/assets/icon3-four.png"
                    @click="changeMask"
                    alt=""
                  />
                </div>
                <p class="text-center f-w700">{{ item.syllables }}</p>
                <div
                  class="
                    img-box
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                  @click="changeMask"
                >
                  <img src="@/assets/icon-four.png" alt="" />
                  <img src="@/assets/icon1-four.png" alt="" />
                  <img src="@/assets/icon2-four.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页器 -->
        <div class="swiper-pagination" slot="pagination"></div>
      </div>
    </div>
    <div class="learn-content m-16">
      <div class="item b-r12 bg-fff co_333">
        <div class="title f-w700">联系记忆</div>
        <div class="d-flex">
          <div class="img">
            <img :src="list[activeIndex].memoryImg" alt="" />
          </div>
          <div class="tips flex" v-html="list[activeIndex].memoryText"></div>
        </div>
      </div>
      <div class="item b-r12 bg-fff co_333">
        <div class="title f-w700">发音技巧</div>
        <div class="d-flex">
          <div class="tips">
            {{ list[activeIndex].pronunciationSkills }}
          </div>
        </div>
      </div>
      <div class="item b-r12 bg-fff co_333">
        <div class="title f-w700">视频讲解</div>
        <div class="d-flex" @click="changeMask">
          <div class="video po-r">
            <div class="play-btn p-a-c">
              <img src="@/assets/playBtn.png" alt="" />
            </div>
            <img src="@/assets/defalut-img.png" alt="" style="width: 100%" />
            <div class="video-mask po-a"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="mask-box pos-fiexd" v-show="maskShow">
      <!-- <div class="mask"></div> -->
      <div class="mask-content p-a-c bg-fff">
        <div class="close text-right">
          <img
            src="@/assets/close.png"
            alt=""
            style="width: 14px; height: 14px"
            @click="changeMask"
          />
        </div>
        <div class="content text-center">
          <div class="text">下载洋葱韩语app</div>
          <div class="btn" @click="download">
            <span>去下载</span>
          </div>
        </div>
      </div>
    </div>

    <downBtn
      :topShow="true"
      :isWx="isWx"
      @down="download"
      @wechatShow="wechatShow"
    ></downBtn>
  </div>
</template>

<script>
import Swiper from 'swiper/swiper-bundle.min.js'
import 'swiper/swiper-bundle.min.css'
import downBtn from '@/components/downBtn'
import mixin from '@/utils/mixin'
import { getSyllableDetail } from '@/api/fortyTone'
import { setTimeout } from 'timers'

export default {
  name: '',
  data() {
    return {
      list: [
        {
          memoryImg: '',
          memoryText: '',
        },
      ],
      activeIndex: 0,
      maskShow: false,
    }
  },
  mixins: [mixin],
  components: {
    downBtn,
  },
  created() {
    console.log(this.$route.query.id, 'this.$router.query.id')
  },
  mounted() {
    this.getSyllableDetail({ id: this.$route.query.id })
  },
  methods: {
    async getSyllableDetail(id) {
      const { data, code } = await getSyllableDetail(id)
      if (code != 'SUCCESS') return
      data.forEach((rose) => {
        rose.memoryText = rose.memoryText.replace('\\n', '</br>')
      })

      this.list = data
      setTimeout(() => {
        let _this = this
        new Swiper('.swiper-container', {
          loop: true, // 循环模式选项
          loopedSlides: 3, // 必设不然最后一张右边没元素
          //自动播放
          /* autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      }, */
          //分页器
          pagination: {
            el: '.swiper-pagination',
            clickable: true, // 允许点击小圆点跳转
          },
          initialSlide: _this.activeIndex,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          coverflow: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          },
          on: {
            slideChangeTransitionEnd: function () {
              _this.activeIndex = this.realIndex
            },
          },
        })
      }, 200)
    },
    changeMask() {
      this.maskShow = !this.maskShow
    },
    changeTabs(idx) {
      this.activeIndex = idx
    },
  },
}
</script>

<style lang="scss" scoped>
.learn {
  background: #f5f6f9;
  min-height: 100vh;
  overflow: hidden;

  .m-16 {
    margin: 0 16px;
  }
  &-header {
    margin-top: 50px;
    margin-bottom: 18px;
    position: relative;
    &-text {
      height: 31px;
      font-size: 20px;
      margin-right: 20px;
      margin-bottom: 22px;
      &:last-child {
        margin-right: 0;
      }
      span {
        padding-bottom: 3px;
        position: relative;
        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          height: 5px;
          width: 100%;
          /* 蓝变紫渐变 */
          background: #f5f6f9;
          border-radius: 3px;
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }
        &.active {
          &::after {
            /* 蓝变紫渐变 */
            background: linear-gradient(270deg, #4e6aff 0%, #88c9ff 100%);
          }
        }
      }
    }
    .swiper_all {
      overflow: hidden;
      .swiper-container {
        overflow: visible !important;
        width: 311px;
        height: 239px;
        margin: 0 auto;
        .swiper-wrapper {
          .swiper-slide-active {
            margin: 0 16px;
          }
          .swiper-slide-prev {
            margin: 0 0 0 -16px;
          } /*由于swiper-slide-active左右设置了16像素，默认往右边挤过去了单独给前一个div设置负16像素左右才能对齐*/
          .swiper-info {
            padding: 8px 16px;
            .img {
              img {
                width: 24px;
                height: 24px;
              }
            }
            p {
              font-size: 100px;
              color: #4e6aff;
            }
            .img-box {
              margin-top: 37px;
              img {
                height: 42px;
                &:nth-child(2) {
                  margin: 0 39px;
                }
              }
            }
          }
        }
      }
      .swiper-pagination {
        top: 0;
        height: 31px;
        /deep/ .swiper-pagination-bullet {
          width: 25px;
          height: 25px;
          // background: #c4c4c4;
          margin: 0 8px;
          opacity: 0;
          border-radius: 0;
          &.swiper-pagination-bullet-active {
            // background: #02897a !important;
            opacity: 0;
          }
        }
      }
    }
  }
  &-content {
    .item {
      padding: 16px;
      margin-bottom: 12px;
      .title {
        font-size: 16px;
        margin-bottom: 13px;
      }
      .img {
        min-width: 146px;
        height: 90px;
        border-radius: 6px;
        box-shadow: 0px 0px 10px rgba(230, 230, 230, 0.5);
        overflow: hidden;
        margin-right: 15px;

        img {
          // width: 100%;
          height: 90px;
        }
      }
      .tips {
        font-size: 14px;
        line-height: 25px;
      }
      .video {
        width: 311px;
        height: 175px;
        box-shadow: 0px 0px 10px rgba(230, 230, 230, 0.5);
        border-radius: 8px;
        overflow: hidden;
        z-index: 8;
        &-mask {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.1);
        }
        .play-btn {
          z-index: 9;
          img {
            width: 52px;
            height: 52px;
          }
        }
      }
    }
  }
}
</style>
